
const months = [
    'yanvar', 'fevral', 'mart', 'aprel', 'may', 'iyun', 'iyul', 'avgust',
    'sentabr', 'oktabr', 'noyabr', 'dekabr'
]
// DATE PROTOTYPES
// Date.prototype.ddmmyyyy = function () {
//     var yyyy = this.getFullYear().toString();
//     var mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
//     var dd = this.getDate().toString();
//     return (dd[1] ? dd : "0" + dd[0]) + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + yyyy; // padding
// };

Date.prototype.daym_shortyyyy = function () {

    var yyyy = this.getFullYear().toString();
    var mm = this.toLocaleString('uz-Cyrl', { month: 'short' }); // getMonth() is zero-based
    var dd = this.getDate().toString();
    return (dd[1] ? dd : "0" + dd[0]) + " " + mm + " " + yyyy; // padding
};

//DD-MM-YYYY, HH:mm:ss FORMAT
Date.prototype.ddmmyyyyhhmmss = function () {
    var yyyy = this.getFullYear().toString();
    var mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
    var dd = this.getDate().toString();
    var hh = this.getHours().toString()
    var min = this.getMinutes().toString()
    var ss = this.getSeconds().toString()
    return (dd[1] ? dd : "0" + dd[0]) + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + yyyy + " " + (hh[1] ? hh : "0" + hh) + ":" + (min[1] ? min : "0" + min) + ":" + (ss[1] ? ss : "0" + ss); // padding
};

//DAY SHORT MONTH NAME YEAR, HH:mm FORMAT
Date.prototype.daym_shortyyyy_hm = function () {
    var yyyy = this.getFullYear().toString();
    var mm = this.toLocaleString('uz-Cyrl', { month: 'short' });
    var dd = this.getDate().toString();
    var hh = this.getHours().toString()
    var min = this.getMinutes().toString()
    return (dd[1] ? dd : "0" + dd[0]) + "-" + mm + "-" + yyyy + " " + (hh[1] ? hh : "0" + hh) + ":" + (min[1] ? min : "0" + min); // padding
};


Date.prototype.hhmm = function () {
    var hh = this.getHours().toString()
    var min = this.getMinutes().toString()
    return (hh[1] ? hh : "0" + hh) + ":" + (min[1] ? min : "0" + min); // padding
};


Date.prototype.hhmmss = function () {
    var hh = this.getHours().toString()
    var min = this.getMinutes().toString()
    var ss = this.getSeconds().toString()
    return (hh[1] ? hh : "0" + hh) + ":" + (min[1] ? min : "0" + min) + ":" + (ss[1] ? ss : "0" + ss) // padding
};

Date.prototype.mm = function () {
    var mm = this.toLocaleString('uz-Cyrl', { month: 'short' });
    return mm
};
Date.prototype.dd = function () {
    var dd = this.getDate().toString();
    return (dd[1] ? dd : "0" + dd[0]) // padding
};

Date.prototype.yyyy = function () {
    var yyyy = this.getFullYear().toString();
    return yyyy
};

Date.prototype.monthNameUz = function () {
    let date = new Date()
    return months[date.getMonth()]
}
Date.prototype.dateUzString = function (ddmmyyyy) {
    if (!ddmmyyyy) return ''
    let dateArr = ddmmyyyy.split('-')
    let monthNameUz = months[+dateArr[1] - 1]
    let day = +dateArr[0]
    return `${dateArr[2]}-yil ${day}-${monthNameUz}`
}

Date.prototype.dateUzSt = function (withoutYear = false) {
    if (!this) return ''
    let date = new Date(this)
    let month = date.getMonth()
    let days = date.getDate()
    let year = date.getFullYear()
    if (withoutYear) {
        return `${days}-${months[month]}`
    }
    return `${year}-yil ${days}-${months[month]}`
}
