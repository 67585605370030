import ApiService from "./api.service";
const main = "getInfo";
export default {
  getList({ params, search }) {
    return ApiService.post(`${main}/postList?search=${search}`, params);
  },
  getById(id) {
    return ApiService.post(`${main}/postGetSeenByPostId?postId=${id || ""}`);
  },
};
