<template>
  <!-- the blinds button -->
  <a @click.prevent="" href="#!">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3C19.5157 3 23.7244 11.2 23.9248 11.6C24.0251 11.8 24.0251 12.2 23.9248 12.4C23.7244 12.8 19.5157 21 12 21C4.92586 21 0.78149 13.7351 0.151837 12.6314C0.112538 12.5625 0.0869305 12.5176 0.0751566 12.5C-0.0250522 12.2 -0.0250522 11.9 0.0751566 11.6C0.275574 11.2 4.48434 3 12 3ZM2.07933 12C3.08142 13.6 6.58873 19 12 19C17.4113 19 20.9186 13.6 21.9207 12C20.9186 10.4 17.4113 5 12 5C6.58873 5 2.98121 10.4 2.07933 12ZM12 8C9.79541 8 7.99165 9.8 7.99165 12C7.99165 14.2 9.79541 16 12 16C14.2046 16 16.0084 14.2 16.0084 12C16.0084 9.8 14.2046 8 12 8ZM9.99582 12C9.99582 13.1 10.8977 14 12 14C13.1023 14 14.0042 13.1 14.0042 12C14.0042 10.9 13.1023 10 12 10C10.8977 10 9.99582 10.9 9.99582 12Z"
        fill="#fff"
      />
      <mask
        id="mask0_346_17659"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="3"
        width="24"
        height="18"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 3C19.5157 3 23.7244 11.2 23.9248 11.6C24.0251 11.8 24.0251 12.2 23.9248 12.4C23.7244 12.8 19.5157 21 12 21C4.92586 21 0.78149 13.7351 0.151837 12.6314C0.112538 12.5625 0.0869305 12.5176 0.0751566 12.5C-0.0250522 12.2 -0.0250522 11.9 0.0751566 11.6C0.275574 11.2 4.48434 3 12 3ZM2.07933 12C3.08142 13.6 6.58873 19 12 19C17.4113 19 20.9186 13.6 21.9207 12C20.9186 10.4 17.4113 5 12 5C6.58873 5 2.98121 10.4 2.07933 12ZM12 8C9.79541 8 7.99165 9.8 7.99165 12C7.99165 14.2 9.79541 16 12 16C14.2046 16 16.0084 14.2 16.0084 12C16.0084 9.8 14.2046 8 12 8ZM9.99582 12C9.99582 13.1 10.8977 14 12 14C13.1023 14 14.0042 13.1 14.0042 12C14.0042 10.9 13.1023 10 12 10C10.8977 10 9.99582 10.9 9.99582 12Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_346_17659)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H24V24H0V0Z"
          fill="#fff"
        />
      </g>
    </svg>
  </a>
</template>

<script>
export default {};
</script>

<style>
</style>