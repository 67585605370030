<template>
    <nav class="fixed w-full">
        <div class="relative flex overflow-x-hidden bg-dark_yellow text-white">
            <div class="animate-marquee whitespace-nowrap">
                <span class="text-2xl mx-4" v-for="(item, index) in siteInfoList" :key="index">
                    {{ item[$localeKey(name)] }}
                </span>
                <span class="text-2xl mx-4" v-for="(item, index) in siteInfoList" :key="index">
                    {{ item[$localeKey(name)] }}
                </span>
                <span class="text-2xl mx-4" v-for="(item, index) in siteInfoList" :key="index">
                    {{ item[$localeKey(name)] }}
                </span>
            </div>

            <div class="absolute top-0 animate-marquee2 whitespace-nowrap">
                <span class="text-2xl mx-4" v-for="(item, index) in siteInfoList" :key="index">
                    {{ item[$localeKey(name)] }}
                </span>
                <span class="text-2xl mx-4" v-for="(item, index) in siteInfoList" :key="index">
                    {{ item[$localeKey(name)] }}
                </span>
                <span class="text-2xl mx-4" v-for="(item, index) in siteInfoList" :key="index">
                    {{ item[$localeKey(name)] }}
                </span>
            </div>
        </div>
        <div class="container px-3 mx-auto flex justify-between">
            <router-link to="/" class="logo py-3 flex items-center">
                <img class="mr-3" src="@/assets/img/logo.png" />
                <p class="title m-0">
                    {{ $t("mainName") }} <br />
                    <strong>{{ $t("mainNameStrong") }}</strong>
                </p>
            </router-link>

            <div class="flex-grow hidden justify-center 2xl:flex">
                <ul class="flex h-full items-center navLinks">
                    <li v-for="(link, index) in links" :key="index">
                        <router-link class-active="active" :to="link.path">
                            {{ $t(link.label) }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="tools flex items-center">
                <div class="the__blind relative flex items-center h-full">
                    <eye-navbar @click.prevent="eyeNavbar = !eyeNavbar"></eye-navbar>
                    <span v-if="eyeNavbar" class="-z-10 flex fixed top-0 left-0 w-full h-screen"
                        @click="eyeNavbar = false"></span>
                    <div :class="{ open: eyeNavbar }" class="eye__tools flex">
                        <span @click="changeBlind('USUAL')"
                            class="flex bg-white w-10 h-10 flex justify-center items-center rounded-full text-xl cursor-pointer">
                            A
                        </span>
                        <span @click="changeBlind('BLIND')"
                            class="flex bg-slate-400 w-10 h-10 flex justify-center items-center rounded-full text-xl ml-2 cursor-pointer">
                            A
                        </span>
                        <span @click="changeBlind('BLIND2')"
                            class="flex bg-slate-700 w-10 h-10 flex justify-center items-center rounded-full text-xl text-white ml-2 cursor-pointer">
                            A
                        </span>
                    </div>
                </div>
                <div class="lang h-full flex items-center relative ml-0 sm:ml-3">
                    <button @click="langBar = !langBar" class="btn">
                        <langIcon />
                    </button>
                    <span v-if="langBar" class="-z-10 flex fixed top-0 left-0 w-full h-screen"
                        @click="langBar = false"></span>
                    <ul class="flex langs flex-col" :class="{ open: langBar }">
                        <li v-for="(lang, index) in langs" :key="index" @click="changeLang(lang.value)"
                            :class="{ active: lang.value == localeLang }" class="cursor-pointer">
                            {{ lang.name }}
                        </li>
                    </ul>
                </div>
                <div v-if="!fullName" class="flex items-center ml-2 relative">
                    <button @click.prevent="loginBtn = !loginBtn" class="btn p-2">
                        <login-icon />
                    </button>
                    <span v-if="loginBtn" class="-z-10 flex fixed top-0 left-0 w-full h-screen"
                        @click="loginBtn = false"></span>
                    <div :class="{ open: loginBtn }" class="eye__tools flex">
                        <ul>
                            <li>
                                <button @click="openOneId" class="cursor-pointer shadow">
                                    <img style="background-color: rgb(72, 37, 194); color: white; border-radius: 10px; width: 100px"
                                        src="@/assets/one-id.png" alt="oneId">
                                </button>
                            </li>
                            <li class="mt-3">
                                <button @click="$emit('sign'); loginBtn = false"
                                    style="background-image: linear-gradient(to bottom, rgb(111,146,192), rgb(66,87,159)); color: white; border-radius: 10px; width: 100px"
                                    class="cursor-pointer shadow flex justify-center items-center">
                                    <img width="20" src="@/assets/e-imzo.png" alt="eimzo"><span class="ml-2 mb-1"
                                        style="font-size: 20px"> - imzo</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else class="user relative items-center ml-2 lg:items-start" style="z-index:99">
                    <span v-if="userBar" class="flex fixed top-0 left-0 w-full h-screen"
                        @click="userBar = false"></span>
                    <a href="#!" @click.prevent="userBar = true" class="flex items-center lg:items-start">
                        <div class="img flex h-full items-center flex-shrink-0">
                            <img v-if="getUploadPath"
                                class="w-9 h-9 sm:w-11 sm:h-11 rounded-full object-cover flex-shrink-0"
                                :src="getBase + getUploadPath" />
                            <img v-else class="w-9 h-9 sm:w-11 sm:h-11 rounded-full object-cover flex-shrink-0"
                                src="@/assets/img/default_male.jpg" />
                        </div>
                        <div class="info hidden lg:flex">
                            <strong>{{ fullName || "" }}</strong>
                            <p v-if="email != 'null' || useremail != 'null'">
                                {{ email || useremail || "" }}
                            </p>
                        </div>
                        <div class="flex h-full flex-col justify-start">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_601_2901" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0"
                                    y="0" width="24" height="24">
                                    <rect width="24" height="24" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_601_2901)">
                                    <path
                                        d="M12 15.375L6 9.37501L7.4 7.97501L12 12.575L16.6 7.97501L18 9.37501L12 15.375Z"
                                        fill="white" fill-opacity="0.87" />
                                </g>
                            </svg>
                        </div>
                    </a>

                    <ul class="flex langs flex-col translate-y-3" :class="{ open: userBar }">
                        <li @click="signIn" class="cursor-pointer">
                            {{ $t("signIn") }}
                        </li>
                        <li @click="logOut" class="cursor-pointer">
                            {{ $t("signOut") }}
                        </li>
                    </ul>
                </div>

                <div class="humburger flex ml-0 sm:ml-3 2xl:hidden">
                    <button @click="colapse = false" class="btn p-0 sm:p-2">
                        <hamburger-icon />
                    </button>
                </div>
            </div>
        </div>
        <!-- <div :class="{ 'scale-0': showTest }"
      class="container transition-all px-3 mx-auto absolute top-[110%] right-[50%] translate-x-[50%]">
      <div id="alert-1" class="flex p-4 mb-4 text-blue-700 bg-blue-100 rounded-lg dark:bg-gray-800 dark:text-blue-400"
        role="alert">
        <svg aria-hidden="true" class="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"></path>
        </svg>
        <span class="sr-only">Info</span>
        <div class="ml-3 text-sm font-medium">
          <span>
            {{ $t("telegramBotClick") }}
            &nbsp;&nbsp;
            <a href="https://t.me/eadvokatuzbot"
              class="font-semibold underline hover:text-blue-800 dark:hover:text-blue-900">E-advokat</a>
          </span>
        </div>
        <button @click="showTest = true" type="button"
          class="ml-auto -mx-1.5 -my-1.5 bg-blue-100 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-blue-400 dark:hover:bg-gray-700"
          data-dismiss-target="#alert-1" aria-label="Close">
          <span class="sr-only">Close</span>
          <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
    </div> -->
    </nav>
    <div class="mask" @click="colapse = true" :class="{ close: colapse }"></div>
    <div class="colapsBar p-8" :class="{ close: colapse }">
        <div class="px-5 mx-auto flex flex-col h-full">
            <div class="w-full flex justify-end">
                <button @click="colapse = true" class="p-2 flex justify-center items-center">
                    <img src="@/assets/img/x.png" />
                </button>
            </div>
            <ul class="flex-grow overflow-y-auto">
                <li v-for="(link, index) in links" :key="index">
                    <router-link @click="changePage(link.path)" :to="link.path">
                        {{ $t(link.label) }}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import NotificationService from "@/services/notifications.service";
import HamburgerIcon from "@/assets/img/svg/hamburgerIcon.vue";
import { TokenService } from "@/services/storage.service";
import LoginIcon from "@/assets/img/svg/loginIcon.vue";
import langIcon from "@/assets/img/svg/langIcon.vue";
import LoginService from "@/services/login.service";
import EyeNavbar from "@/components/eyeNavbar.vue";
import ApiService from "@/services/api.service";
import { mapState, mapMutations } from "vuex";

const base2 = process.env.VUE_APP_ROOT_URL2;
const base = process.env.VUE_APP_ROOT_URL;
const location = window.location.origin;
export default {
    components: {
        langIcon,
        HamburgerIcon,
        LoginIcon,
        EyeNavbar,
    },
    computed: {
        ...mapState("lawyer", ["email", "fullName"]),
        getBase() {
            // base + '/'
            if (this.getUploadPath?.indexOf("files") > -1) return `${base}/`;
            else return base2;
        },
    },
    data() {
        return {
            base,
            base2,
            useremail: "",
            userfullname: "",
            getUploadPath: "",
            siteInfoList: [],
            loginBtn: false,
            langBar: false,
            userBar: false,
            colapse: true,
            showTest: false,
            eyeNavbar: false,
            localeLang: "lt",
            TheBlind: "USUAL",
            links: [
                {
                    label: "home",
                    path: "/",
                },
                {
                    label: "news",
                    path: "/news",
                },
                {
                    label: "lawyers",
                    path: "/lawyer",
                },
                {
                    label: "departments",
                    path: "/contragent",
                },
                {
                    label: "legislation",
                    path: "/legislation",
                },
                {
                    label: "dutySchedule",
                    path: "/duty-table",
                },
                // {
                //     label: "announcement.title",
                //     path: "/announcement",
                // },
            ],
            langs: [
                {
                    name: "Русский",
                    value: "ru",
                },
                {
                    name: "O‘zbek (Lotin)",
                    value: "lt",
                },
                {
                    name: "Ўзбек (кирилл)",
                    value: "uz",
                },
                {
                    name: "English",
                    value: "eng",
                },
            ],
        };
    },
    watch: {
        "$route.path": function () {
            this.userfullname = TokenService.getFullName() || "";
            this.useremail = TokenService.getEmail() || "";
            this.getUploadPath = TokenService.getUploadPath() || "";
        },
        langBar(e) {
            if (e) {
                this.userBar = false;
                this.eyeNavbar = false;
                this.loginBtn = false;
            }
        },
        userBar(e) {
            if (e) {
                this.langBar = false;
                this.eyeNavbar = false;
                this.loginBtn = false;
            }
        },
        eyeNavbar(e) {
            if (e) {
                this.langBar = false;
                this.userBar = false;
                this.loginBtn = false;
            }
        },
        loginBtn(e) {
            if (e) {
                this.langBar = false;
                this.userBar = false;
                this.eyeNavbar = false;
            }
        },
    },
    methods: {
        ...mapMutations("lawyer", ["USER"]),
        changeBlind(mode) {
            TokenService.setTheBlind(mode);
            this.eyeNavbar = false;
            this.setBlind();
        },
        setBlind() {
            let mode = TokenService.getTheBlind() || "USUAL";
            document.body.classList.remove("USUAL", "BLIND", "BLIND2");
            document.body.classList.add(mode);
        },
        openOneId() {
            if (this.base.indexOf("e-advokat") > -1) {
                this.WinPrint = window.open(
                    `https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=e-advokat&redirect_uri=${location}/one-id&scope=e-advokat&state=eyJtZXRob2QiOiJJRFBXIn0=`,
                    "_self",
                    "One Id",
                    "left=0,top=0,width=1000,height=1000,toolbar=0,scrollbars=0,status=0"
                );
            } else {
                this.WinPrint = window.open(
                    "https://sso.egov.uz/sso/oauth/Authorization.do?response_type=one_code&client_id=e-advokat&redirect_uri=http://localhost:1100/one-id&scope=e-advokat&state=eyJtZXRob2QiOiJJRFBXIn0=",
                    "_self",
                    "One Id",
                    "left=0,top=0,width=1000,height=1000,toolbar=0,scrollbars=0,status=0"
                );
            }
        },
        async logOut() {
            try {
                await LoginService.logOut();
            } finally {
                this.userBar = false;
                this.USER({ email: "", fullName: "" });
                TokenService.removeToken();
                ApiService.removeHeader();
                TokenService.removeEmail();
                TokenService.removeFullName();
                this.userfullname = TokenService.getFullName() || "";
                this.useremail = TokenService.getEmail() || "";
                this.$router.push({ name: "Home" });
            }
        },
        async getSiteInfoList() {
            try {
                this.loading = true;

                let payload = {
                    page: 0,
                    limit: 10000,
                };

                const response = await NotificationService.getSiteInfoList(payload)

                if (response.status === 200) {
                    this.siteInfoList = response.data?.list;
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        changeLang(lang) {
            localStorage.setItem("language", lang);
            this.$i18n.locale = lang;

            this.localeLang = lang;
            this.langBar = false;
            // if (lang == "uz") {
            //   this.$router.go();
            // }
        },
        changePage(path) {
            this.colapse = true;
            this.$router.push(path);
        },
        signIn() {
            let role = TokenService.getUserRoles();
            if (role == "LAWYER" || role == "DIRECTOR")
                this.$router.push({ path: "/lawyer-profile" });
            else this.$router.push({ path: "/lawyer-profile/user-info" });
        },
    },
    created() {
        this.localeLang = localStorage.getItem("language") || "lt";
        this.getUploadPath = TokenService.getUploadPath() || "";
    },
    mounted() {
        this.setBlind();
        this.getSiteInfoList();
    },
};
</script>

<style lang="scss" scoped>
.user {
    @apply flex cursor-pointer;

    .info {
        @apply h-full justify-center flex-col ml-2;

        strong {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.87);
            margin-bottom: 2px;
        }

        p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(255, 255, 255, 0.87);
            margin-bottom: 0;
        }
    }
}

.mask {
    display: flex;
    position: fixed;
    @apply w-full h-screen top-0 left-0;
    background: rgba(0, 27, 92, 0.4);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(8px);
    z-index: 1000;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s;

    &.close {
        opacity: 0;
        visibility: hidden;
    }
}

.colapsBar {
    position: fixed;
    z-index: 1000;
    @apply bg-blue_main w-full sm:w-2/3 md:w-1/3 lg:w-2/5 2xl:w-1/5;
    top: 0;
    right: 0;
    height: 100vh;
    transition: transform 0.5s;
    transform: translateX(0);

    &.close {
        transform: translateX(100%);
    }

    ul {
        @apply flex flex-col mt-10;

        li {
            @apply flex items-center w-full;

            a {
                @apply flex items-center w-full justify-center py-2;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #ffffff;
                transition: color 0.5s;

                &.router-link-exact-active,
                &:hover {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    color: #c1853d;
                }
            }
        }
    }
}

nav {
    position: fixed;
    z-index: 1000;
    background: rgba(0, 27, 92, 0.6);
    border: 1px solid rgba(0, 27, 92, 0.4);
    backdrop-filter: blur(8px);

    .logo {
        .title {
            @media (max-width: 450px) {
                display: none;
            }

            display: initial;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #ffffff;
        }
    }

    .eye__tools {
        min-width: 158px;
        padding: 24px;
        background: rgba(0, 27, 92, 0.6);
        border: 2px solid #c1853d;
        box-shadow: 0px 5px 10px -5px rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        position: absolute;
        top: calc(100%);
        right: 0;
        transition: 0.2s;
        transform: translateY(10px) scale(0);

        &.open {
            transform: translateY(10px) scale(1);
        }
    }

    ul.langs {
        min-width: 158px;
        padding: 24px;
        background: rgba(0, 27, 92, 0.6);
        border: 2px solid #c1853d;
        box-shadow: 0px 5px 10px -5px rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        position: absolute;
        top: calc(100%);
        right: 0;
        transition: 0.2s;
        transform: translateY(10px) scale(0);

        &.open {
            transform: translateY(10px) scale(1);
        }

        li {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.8);
            padding-bottom: 16px;
            transition: color 0.5s;

            &.active,
            &:hover {
                color: #c1853d;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .user {
        ul.langs {
            top: calc(100% + 10px);
        }
    }

    ul.navLinks {
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            a {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                padding: 8px 18px;
                margin: 0 2px;
                color: rgba(255, 255, 255, 0.87);
                transition: color 0.5s;

                &.router-link-exact-active {
                    font-weight: 600;
                    color: #ce8d40;
                }

                &:hover {
                    font-weight: 600;
                    color: #ce8d40;
                }
            }
        }
    }
}
</style>
