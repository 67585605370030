<template>
  <footer class="bg-blue_main py-24 mt-8">
    <div class="container mx-auto px-3">
      <div class="grid grid-cols-12">
        <div
          class="
            col-span-12
            md:col-span-3
            mb-0
            sm:mb-6
            md:mb-0
            logo
            order-1
            flex flex-col
            items-center
          "
        >
          <img src="@/assets/img/logo_footer.png" />
          <p class="text-center mt-2">
            {{ $t("mainName") }} <br />
            <strong>{{ $t("mainNameStrong") }}</strong>
          </p>
        </div>
        <div
          class="
            flex
            justify-center
            sm:justify-start
            col-span-12
            mt-8
            xl:m-0 xl:col-span-3
            order-5
            xl:order-2
          "
        >
          <ul class="flex flex-col items-center sm:items-start">
            <li>
              <a @click.prevent="" href="#!">
                <img src="@/assets/img/svg/locate.svg" />
                {{ $t("adres") }}
              </a>
            </li>
            <li>
              <a href="mailto:info@adliya.uz">
                <img src="@/assets/img/svg/message.svg" />
                info@adliya.uz
              </a>
            </li>
            <li>
              <a href="tel:+998 71 207 04 43">
                <img src="@/assets/img/svg/call.svg" />
                +998 71 207 04 43
              </a>
            </li>
          </ul>
        </div>
        <div
          class="
            hidden
            sm:flex
            justify-center
            md:justify-start
            col-span-4
            md:col-span-3
            xl:col-span-2
            order-2
            xl:order-3
          "
        >
          <ul class="flex only_text flex-col">
            <li>
              <a
                href="https://www.minjust.uz/uz/about/pravovie_osnovi/"
                target="_blank"
              >
                {{ $t("aboutMinistry") }}
              </a>
            </li>
            <li>
              <a
                href="https://www.minjust.uz/uz/activity/legal_education/"
                target="_blank"
              >
                {{ $t("ourCareer") }}
              </a>
            </li>
            <li>
              <a href="https://www.minjust.uz/uz/opendata/" target="_blank">
                {{ $t("openJustice") }}
              </a>
            </li>
            <li>
              <a
                href="https://www.minjust.uz/uz/about/jobs/vac_questions/"
                target="_blank"
              >
                {{ $t("vacancy") }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="
            hidden
            sm:flex
            justify-center
            md:justify-start
            col-span-4
            md:col-span-3
            xl:col-span-2
            order-3
            xl:order-4
          "
        >
          <ul class="flex only_text flex-col">
            <li>
              <a
                href="https://www.minjust.uz/uz/contacts/territorial/"
                target="_blank"
              >
                {{ $t("appeal") }}
              </a>
            </li>
            <li>
              <a
                href="https://www.minjust.uz/uz/press-center/news/"
                target="_blank"
              >
                {{ $t("serviceInformation") }}
              </a>
            </li>
            <li>
              <a
                href="https://www.minjust.uz/uz/library/own_publications/"
                target="_blank"
              >
                {{ $t("e_library") }}
              </a>
            </li>
            <li>
              <a
                href="https://www.minjust.uz/uz/interactive/gov-ser-stat/"
                target="_blank"
              >
                {{ $t("interactive") }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="
            hidden
            sm:flex
            justify-center
            md:justify-start
            col-span-4
            md:col-span-3
            xl:col-span-2
            order-4
            xl:order-5
          "
        >
          <ul class="flex only_text flex-col">
            <li>
              <a
                href="https://www.minjust.uz/uz/contacts/institutions/"
                target="_blank"
              >
                {{ $t("contact") }}
              </a>
            </li>
            <li>
              <a
                href="https://www.minjust.uz/uz/anticorruption/documents/"
                target="_blank"
              >
                {{ $t("corruption") }}
              </a>
            </li>
            <li>
              <a href="https://www.minjust.uz/uz/gender/" target="_blank">
                {{ $t("genderEquality") }}
              </a>
            </li>
          </ul>
        </div>
        <div
          class="
            col-span-12
            flex
            justify-center
            sm:justify-start sm:col-span-8
            mt-6
            sm:mt-16
            order-7
            sm:order-6
          "
        >
          <p class="copy">
            © 2005-2022 {{ $t("mainName") }} {{ $t("mainNameStrong") }}
          </p>
        </div>
        <div class="col-span-12 sm:col-span-4 mt-6 sm:mt-16 order-6 sm:order-7">
          <div class="links">
            <a
              class="hover:scale-110 transition"
              href="https://www.facebook.com/minjustuz/"
              ><svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.3035 12.7334C24.3035 19.3608 18.9309 24.7334 12.3035 24.7334C5.67605 24.7334 0.303467 19.3608 0.303467 12.7334C0.303467 6.10598 5.67605 0.733398 12.3035 0.733398C18.9309 0.733398 24.3035 6.10598 24.3035 12.7334ZM13.8995 13.2032H16.0406L16.324 10.4488H13.8997V8.82632C13.8997 8.21774 14.3018 8.07485 14.5878 8.07485H16.3313V5.39882L13.9283 5.38876C11.2614 5.38876 10.6555 7.38648 10.6555 8.66225V10.4463H9.11259V13.2032H10.6555V21.0477H13.8995V13.2032Z"
                  fill="#D28C3B"
                />
              </svg>
            </a>
            <a
              class="hover:scale-110 transition"
              href="https://www.youtube.com/c/adliya"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_306_15271)">
                  <path
                    d="M10.6393 14.6694L13.9675 12.7384L10.6393 10.7979V14.6694Z"
                    fill="#D28C3B"
                  />
                  <path
                    d="M12.3035 0.733398C9.9301 0.733398 7.61002 1.43719 5.63663 2.75576C3.66324 4.07434 2.12517 5.94848 1.21692 8.1412C0.308666 10.3339 0.0710261 12.7467 0.534049 15.0745C0.997072 17.4023 2.13996 19.5405 3.81819 21.2187C5.49642 22.8969 7.63462 24.0398 9.96239 24.5028C12.2902 24.9658 14.703 24.7282 16.8957 23.82C19.0884 22.9117 20.9625 21.3736 22.2811 19.4002C23.5997 17.4269 24.3035 15.1068 24.3035 12.7334C24.3035 9.5508 23.0392 6.49855 20.7888 4.24812C18.5383 1.99768 15.4861 0.733398 12.3035 0.733398ZM18.6193 14.8571C18.6195 15.1709 18.5578 15.4818 18.4379 15.7718C18.3179 16.0618 18.142 16.3253 17.9201 16.5473C17.6983 16.7693 17.4348 16.9455 17.1449 17.0656C16.855 17.1858 16.5442 17.2476 16.2303 17.2476H8.37663C8.06278 17.2476 7.752 17.1858 7.46206 17.0656C7.17211 16.9455 6.90869 16.7693 6.68683 16.5473C6.46498 16.3253 6.28904 16.0618 6.16908 15.7718C6.04912 15.4818 5.98748 15.1709 5.98769 14.8571V10.6097C5.98748 10.2959 6.04912 9.98504 6.16908 9.69502C6.28904 9.405 6.46498 9.14146 6.68683 8.91945C6.90869 8.69745 7.17211 8.52134 7.46206 8.40119C7.752 8.28103 8.06278 8.21919 8.37663 8.21919H16.2303C16.5442 8.21919 16.855 8.28103 17.1449 8.40119C17.4348 8.52134 17.6983 8.69745 17.9201 8.91945C18.142 9.14146 18.3179 9.405 18.4379 9.69502C18.5578 9.98504 18.6195 10.2959 18.6193 10.6097V14.8571Z"
                    fill="#D28C3B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_306_15271">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.303467 0.733398)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              class="hover:scale-110 transition"
              href="https://t.me/eadvokatuzbot"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.3035 12.7334C24.3035 19.3608 18.9309 24.7334 12.3035 24.7334C5.67605 24.7334 0.303467 19.3608 0.303467 12.7334C0.303467 6.10598 5.67605 0.733398 12.3035 0.733398C18.9309 0.733398 24.3035 6.10598 24.3035 12.7334ZM17.364 8.0121L5.79461 12.4731C5.00165 12.7831 5.01565 13.2221 5.65862 13.4261L8.62047 14.3501L15.4911 10.0241C15.8101 9.81211 16.1061 9.9321 15.8641 10.1451L10.3044 15.1681L10.0914 18.2211C10.4014 18.2211 10.5364 18.0861 10.6964 17.9261L12.1433 16.5321L15.1431 18.7431C15.6901 19.0531 16.0811 18.8931 16.2271 18.2351L18.197 8.9541L18.196 8.9551C18.37 8.1431 17.901 7.8181 17.364 8.0121Z"
                  fill="#D28C3B"
                />
              </svg>
            </a>
            <a
              class="hover:scale-110 transition"
              href="https://www.instagram.com/adliyanews/"
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8524 7.55469H9.75929C8.3087 7.55469 7.12476 8.73335 7.12476 10.1894V15.2827C7.12476 16.7334 8.30336 17.9174 9.75929 17.9174H14.8524C16.303 17.9174 17.4869 16.7387 17.4869 15.2827V10.1894C17.4816 8.73335 16.303 7.55469 14.8524 7.55469ZM12.3032 16.1414C10.4259 16.1414 8.89533 14.6107 8.89533 12.7334C8.89533 10.856 10.4259 9.32535 12.3032 9.32535C14.1804 9.32535 15.711 10.856 15.711 12.7334C15.711 14.6107 14.1804 16.1414 12.3032 16.1414ZM15.8817 9.70402C15.5617 9.70402 15.3057 9.44802 15.3057 9.12802C15.3057 8.80802 15.5617 8.55202 15.8817 8.55202C16.2016 8.55202 16.4576 8.80802 16.4576 9.12802C16.4576 9.44802 16.2016 9.70402 15.8817 9.70402Z"
                  fill="#D28C3B"
                />
                <path
                  d="M14.375 12.7586C14.3887 11.6158 13.4734 10.6782 12.3306 10.6645C11.1878 10.6508 10.2502 11.5661 10.2365 12.709C10.2228 13.8518 11.1381 14.7894 12.2809 14.8032C13.4237 14.8169 14.3612 13.9015 14.375 12.7586Z"
                  fill="#D28C3B"
                />
                <path
                  d="M12.3035 0.733398C5.67413 0.733398 0.303467 6.10406 0.303467 12.7334C0.303467 19.3627 5.67413 24.7334 12.3035 24.7334C18.9328 24.7334 24.3035 19.3627 24.3035 12.7334C24.3035 6.10406 18.9328 0.733398 12.3035 0.733398ZM18.9701 15.2827C18.9701 17.5547 17.1248 19.4001 14.8528 19.4001H9.75947C7.48747 19.4001 5.64213 17.5547 5.64213 15.2827V10.1894C5.64213 7.9174 7.48747 6.07207 9.75947 6.07207H14.8528C17.1248 6.07207 18.9701 7.9174 18.9701 10.1894V15.2827Z"
                  fill="#D28C3B"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-3">
        <a :href="visitorsCounterLiskPath" target="_blank">
          <img
            :src="visitorCounterImagePathPart + top_r + top_rat"
            width="88"
            height="31"
            border="0"
            alt="Топ рейтинг www.uz"
          />
        </a>

        <NOSCRIPT
          ><a :href="visitorsCounterLiskPath" target="_top"
            ><img
              height="31"
              :src="visitorCounterNoscriptImagePath"
              width="88"
              border="0"
              alt="Топ рейтинг www.uz" /></a
        ></NOSCRIPT>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      top_r:
        "id=45925&r=" +
        escape(document.referrer) +
        "&pg=" +
        escape(window.location.href),
      top_rat: "&col=0063AF&t=ffffff&p=DD7900",
      visitorsCounterLiskPath: "http://www.uz/ru/res/visitor/index?id=45925",
      visitorCounterImagePathPart: "https://cnt0.www.uz/counter/collect?",
      visitorCounterNoscriptImagePath:
        "https://cnt0.www.uz/counter/collect?id=45925&pg=http%3A//uzinfocom.uz&&col=0063AF&amp;t=ffffff&amp;p=DD7900",
    };
  },
};
</script>

<style lang="scss" scoped>
.links {
  @apply flex items-center justify-center sm:justify-end;
  a {
    margin: 0 12px;
  }
}
.logo {
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
  }
}
.copy {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #707070;
}
ul {
  &.only_text {
    a {
      text-transform: uppercase;
    }
    @media (max-width: 768px) {
      align-items: center;
    }
  }
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 17px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      display: flex;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #d9d9d9;
      img {
        display: flex;
        width: 25px;
        height: 25px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}
</style>