<template>
  <div class="flex flex-col h-full pb-6 relative" :class="`form__input__${size}`">
    <label v-if="label" class="mb-3 text-[#001B5C99] flex-grow" :class="{ '!text-red-500': v.$error }">{{ label }}</label>
    <c-input-naive v-model:value="model" :disabled="disabled" :placeholder="placeholder" :loading="loading" :size="size"
      :v="v.$error" :maska="maska" :type="type">
      <template #prefix>
        <slot name="prefix"></slot>
      </template>
      <template #suffix>
        <slot name="suffix"></slot>
      </template>
    </c-input-naive>
    <span v-if="v.$error" class="absolute bottom-0 left-4 transition">
      {{ message || $t("fieldMandatory") }}
    </span>
  </div>
</template>

<script>
import CInputNaive from "../CInputNaive.vue";
export default {
  components: { CInputNaive },
  props: {
    value: {
      type: String,
      default: "",
    },
    maska: {
      default: "",
    },
    v: {
      type: Object,
      default: () => {
        return {
          $error: false,
          $touch: () => { },
        };
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    label: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form__input {
  &__large {
    label {
      font-size: 18px;
    }
  }
}

label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

span {
  font-family: "Poppins";
  font-weight: 200;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  color: #d32f2f;
}
</style>