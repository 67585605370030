<template>
  <span class="flex p-3 items-center justify-center">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9999 23C20.6999 23 21.9999 21.7 21.9999 20V4C21.9999 2.3 20.6999 1 18.9999 1H13.9999C13.3999 1 12.9999 1.4 12.9999 2C12.9999 2.6 13.3999 3 13.9999 3H18.9999C19.5999 3 19.9999 3.4 19.9999 4V20C19.9999 20.6 19.5999 21 18.9999 21H13.9999C13.3999 21 12.9999 21.4 12.9999 22C12.9999 22.6 13.3999 23 13.9999 23H18.9999ZM15.8999 11.6C15.9999 11.9 15.9999 12.2 15.8999 12.4C15.8999 12.5 15.7999 12.6 15.6999 12.7L11.6999 16.7C11.4999 16.9 11.1999 17 10.9999 17C10.7999 17 10.4999 16.9 10.2999 16.7C9.89988 16.3 9.89988 15.7 10.2999 15.3L12.5999 13H2.99988C2.39988 13 1.99988 12.6 1.99988 12C1.99988 11.4 2.39988 11 2.99988 11H12.5999L10.2999 8.7C9.89988 8.3 9.89988 7.7 10.2999 7.3C10.6999 6.9 11.2999 6.9 11.6999 7.3L15.6999 11.3C15.7499 11.35 15.7749 11.4 15.7999 11.45C15.8249 11.5 15.8499 11.55 15.8999 11.6Z"
        fill="white"
      />
      <mask
        id="mask0_501_4499"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="21"
        height="22"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.9999 23C20.6999 23 21.9999 21.7 21.9999 20V4C21.9999 2.3 20.6999 1 18.9999 1H13.9999C13.3999 1 12.9999 1.4 12.9999 2C12.9999 2.6 13.3999 3 13.9999 3H18.9999C19.5999 3 19.9999 3.4 19.9999 4V20C19.9999 20.6 19.5999 21 18.9999 21H13.9999C13.3999 21 12.9999 21.4 12.9999 22C12.9999 22.6 13.3999 23 13.9999 23H18.9999ZM15.8999 11.6C15.9999 11.9 15.9999 12.2 15.8999 12.4C15.8999 12.5 15.7999 12.6 15.6999 12.7L11.6999 16.7C11.4999 16.9 11.1999 17 10.9999 17C10.7999 17 10.4999 16.9 10.2999 16.7C9.89988 16.3 9.89988 15.7 10.2999 15.3L12.5999 13H2.99988C2.39988 13 1.99988 12.6 1.99988 12C1.99988 11.4 2.39988 11 2.99988 11H12.5999L10.2999 8.7C9.89988 8.3 9.89988 7.7 10.2999 7.3C10.6999 6.9 11.2999 6.9 11.6999 7.3L15.6999 11.3C15.7499 11.35 15.7749 11.4 15.7999 11.45C15.8249 11.5 15.8499 11.55 15.8999 11.6Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_501_4499)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-0.00012207 0H23.9999V24H-0.00012207V0Z"
          fill="white"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style>
</style>