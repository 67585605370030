<template>
    <div class="body">
        <slot />
    </div>
</template>

<script>
    export default {};
</script>

<style scoped lang="scss">
.body {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 16px;
}
</style>
