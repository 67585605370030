const TOKEN_KEY = "access_token";
const USERNAME = "userName";
const ROLE = "role";
const EXPANDED = "expanded";
const FULLNAME = "fullName";
const PATH = "path";
const EMAIL = "email";
const LANGUAGE = "language";
const EXPANDEDD = "expanded_order_type";
const DOCSEND = "document_send";
const THE_BLIND = "the_blind";
const PNFL = "pnfl";
const CONTRAGENTID = "contragentId";

const TokenService = {
  setUploadPath(data) {
    return localStorage.setItem(PATH, data);
  },
  removeUploadPath() {
    return localStorage.removeItem(PATH);
  },
  getUploadPath() {
    return localStorage.getItem(PATH);
  },
  setLanguage(data) {
    return localStorage.setItem(LANGUAGE, data);
  },
  removeLanguage() {
    return localStorage.removeItem(LANGUAGE);
  },
  getLanguage() {
    return localStorage.getItem(LANGUAGE);
  },
  setEmail(data) {
    return localStorage.setItem(EMAIL, data);
  },
  removeEmail() {
    return localStorage.removeItem(EMAIL);
  },
  getEmail() {
    return localStorage.getItem(EMAIL);
  },
  setFullName(data) {
    return localStorage.setItem(FULLNAME, data);
  },
  removeFullName() {
    return localStorage.removeItem(FULLNAME);
  },
  getFullName() {
    return localStorage.getItem(FULLNAME);
  },
  setPnfl(data) {
    return localStorage.setItem(PNFL, data);
  },
  removePnfl() {
    return localStorage.removeItem(PNFL);
  },
  getPnfl() {
    return localStorage.getItem(PNFL);
  },

  setExpanded2(data) {
    return localStorage.setItem(EXPANDEDD, JSON.stringify(data));
  },
  removeExpanded2() {
    return localStorage.removeItem(EXPANDEDD);
  },
  getExpanded2() {
    return JSON.parse(localStorage.getItem(EXPANDEDD));
  },

  setExpanded(data) {
    return localStorage.setItem(EXPANDED, JSON.stringify(data));
  },
  removeExpanded() {
    return localStorage.removeItem(EXPANDED);
  },
  getExpanded() {
    return JSON.parse(localStorage.getItem(EXPANDED));
  },
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  saveToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
  saveUserRoles(role) {
    localStorage.setItem(ROLE, JSON.stringify(role));
  },
    saveContragent(contragentId) {
        localStorage.setItem(CONTRAGENTID, contragentId);
    },
    getContragentId() {
        return JSON.parse(localStorage.getItem(CONTRAGENTID));
    },
  getUserRoles() {
    return JSON.parse(localStorage.getItem(ROLE));
  },
  removeUserRoles() {
    localStorage.removeItem(ROLE);
  },
  setUserName(userName) {
    localStorage.setItem(USERNAME, userName);
  },
  getUserName() {
    return localStorage.getItem(USERNAME);
  },
  removeUserName() {
    return localStorage.removeItem(USERNAME);
  },
  setDocSend(docsend) {
    localStorage.setItem(DOCSEND, docsend);
  },
  getDocSend() {
    return localStorage.getItem(DOCSEND);
  },
  removeDocSend() {
    return localStorage.removeItem(DOCSEND);
  },
  setTheBlind(theblind) {
    localStorage.setItem(THE_BLIND, theblind);
  },
  getTheBlind() {
    return localStorage.getItem(THE_BLIND);
  },
  removeTheBlind() {
    return localStorage.removeItem(THE_BLIND);
  },
};

export { TokenService };
