import Main from "@/services/order.service";

const state = () => {
  return {
    list: [],
    contrahentOrders: [],
    contragentTotal: 0,
    total: 0,
    loading: false,
    legalAids: [],
    orderBases: [],
    listForRequest: [],
  };
};

const getters = {};

const actions = {
  async getListForRequest({ commit }) {
    try {
      const response = await Main.getListForRequest();
      commit("setListForRequest", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getList({ commit, state }, data) {
    state.loading = true;
    try {
      const response = await Main.getList(data);
      commit("setList", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      state.loading = false;
    }
  },
  async getLegalAidList({ commit }) {
    try {
      const response = await Main.listOwnLegalAidType();
      commit("setLegalAidList", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getorderBaseList({ commit }) {
    try {
      const response = await Main.orderBaseList();
      commit("setOrderBaseList", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getContragentOrders({ commit, state }, data) {
    state.loading = true;
    try {
      const response = await Main.getContragentOrder(data);
      commit("setListContragent", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      state.loading = false;
    }
  },
};

const mutations = {
  setList(state, data) {
    state.list = data.list;
    state.total = data.total;
  },
  setLegalAidList(state, data) {
    state.legalAids = data?.find((f) => f.id === 18)
      ? data
      : [
          ...data,
          {
            id: 18,
            code: "6",
            nameEn: "another case",
            nameUz: "бошқа иш",
            nameRu: "по иному делу",
            nameLt: "boshqa ish",
          },
        ];
  },
  setListForRequest(state, data) {
    state.listForRequest = data;
  },
  setOrderBaseList(state, data) {
    state.orderBases = data.list;
  },
  setListContragent(state, data) {
    state.contrahentOrders = data.list.reverse();
    state.contragentTotal = data.total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
