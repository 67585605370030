import ApiService from "./api.service";
const main = "getInfo";

export default {
  // requestTemplateList({ params }) {
  //   return ApiService.post(`${main}/requestTemplateList`, params);
  // },
  requestTemplateList({ params, requestTypeId, search, status }) {
    return ApiService.post(
      `${main}/listByPnfl?requestTypeId=${requestTypeId || ""}&search=${
        search || ""
      }&status=${status || []}`,
      params
    );
  },
  requestTypeList() {
    return ApiService.post(`${main}/requestTypeList`);
  },
  getByRequestTemplateId(id) {
    return ApiService.post(
      `${main}/getByRequestTemplateId?requestTemplateId=${id}`
    );
  },
  requestCertificate(data) {
    // let form = new FormData();
    // for (let key in data) {
    //   form.append(key, data[key]);
    // }
    return ApiService.post(`${main}/requestCertificate`, data);
  },
  // /api/v.1/getInfo/reSendReqCert
  reSendReqCert(data) {
    // let form = new FormData();
    // for (let key in data) {
    //   form.append(key, data[key]);
    // }
    return ApiService.post(`${main}/reSendReqCert`, data);
  },
  requestTypeByParentId(id) {
    return ApiService.post(`${main}/requestTypeByParentId?parentId=${id}`);
  },
  // GET http://localhost:8282/api/v.1/getInfo/getHistoryByReqCertId
  getHistoryByReqCertId(id) {
    return ApiService.get(`${main}/getHistoryByReqCertId?id=${id}`);
  },
  // http://localhost:8282/api/v.1/getInfo/getByReqCertId?id=12
  getByReqCertId(id) {
    return ApiService.get(`${main}/getByReqCertId?id=${id}`);
  },
};
