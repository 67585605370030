import candidateService from "@/services/candidate.service";

const state = () => {
  return {
    ownEmployeeList: [],
    total: 0,
    loading: false,
  };
};

const actions = {
  async getOwnEmployeeList({ commit, state }) {
    state.loading = true;
    try {
      const response = await candidateService.ownEmployee();
      commit("setOwnEmployeeList", response.data);
    } catch (error) {
      console.log(error);
    } finally {
      state.loading = false;
    }
  },
};

const getters = {};

const mutations = {
  setOwnEmployeeList(state, data) {
    data
      ? (state.ownEmployeeList = data.map((e) => {
          return {
            ...e,
            label: `${e.lastName || ""} ${e.firstName || ""} ${
              e.parentName || ""
            }`,
          };
        }))
      : (state.ownEmployeeList = []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
