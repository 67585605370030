import Main from "@/services/legalAidDisabled.service";

const state = () => {
  return {
    list: [],
    contrahentOrders: [],
    contragentTotal: 0,
    total: 0,
    loading: false,
    legalAids: [],
    orderBases: [],
    appeals: [],
  };
};

const getters = {};

const actions = {
  async getAppeals({ commit }) {
    try {
      const response = await Main.fieldsOfApplicationList({
        search: "",
        params: {
          page: 0,
          limit: 1000,
        },
      });
      commit("setList", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getLegalAids({ commit }) {
    try {
      const response = await Main.directoryLegalAidList({
        search: "",
        params: {
          page: 0,
          limit: 1000,
        },
      });
      commit("setLegal", response.data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setList(state, data) {
    // state.appeals = data?.list?.sort((a, b) => a.code.localeCompare(b.code));
    state.appeals = data?.list;
  },
  setLegal(state, data) {
    state.legalAids = data.list;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
