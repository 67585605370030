<template>
  <span class="flex p-3 items-center justify-center">
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1H25"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M0.999878 9H24.9999"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M0.999878 17H24.9999"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style>
</style>