/* eslint-disable*/

import { createI18n } from "vue-i18n";
// Localisation language list
import { locale as lt } from "@/core/i18n/locales/uzLotin";
import { locale as uz } from "@/core/i18n/locales/uzCrill";
import { locale as ru } from "@/core/i18n/locales/ru";
import { locale as eng } from "@/core/i18n/locales/en";
import { langMethods } from "@/core/utils";

let messages = {};
messages = { ...messages, lt, uz, ru, eng };

// get current selected language
const lang = localStorage.getItem("language") || "lt";

// Create VueI18n instance with options
const i18n = createI18n({
  locale: lang, // set locale
  messages, // set locale messages
  fallbackLocale: ["uz"],
  missing: (locale, message) => {
    if (locale == "uz") {
      if (getObjvalue(messages["lt"], message.split("."))) {
        return langMethods.toCyrill(
          getObjvalue(messages["lt"], message.split("."))
        );
      }
    }
    return message;
  },
  silentTranslationWarn: true,
});

function getObjvalue(obj, keys) {
  for (let i = 0; i < keys.length; i++) {
    obj = obj[keys[i]];
  }
  return obj;
}
export default i18n;
