import LegalAidService from "@/services/LegalAid.service";

const state = () => {
  return {
    deportaments: [],
  };
};

const getters = {};

const actions = {
  async getList({ commit }) {
    try {
      let res = await LegalAidService.departmentAdliyaList();
      if (res?.data) {
        commit("setList", res?.data);
      }
    } catch {
      commit("setList", []);
    }
  },
};

const mutations = {
  setList(state, payload) {
    state.deportaments = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
