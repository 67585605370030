import ApiService from "./api.service";
let main = "legalAidDisabled";
export default {
  // legalAidDisabledListSend
  appealsList({ search, params, fieldsOfApplicationId, legalAidId, regionId }) {
    return ApiService.post(
      `${main}/legalAidDisabledListSend?fieldsOfApplicationId=${
        fieldsOfApplicationId || ""
      }&legalAidId=${legalAidId || ""}&regionId=${regionId || ""}&search=${
        search || ""
      }`,
      params
    );
  },
  myList({ search, params, fieldsOfApplicationId, legalAidId }) {
    return ApiService.post(
      `${main}/ownLegalAidDisabledList?fieldsOfApplicationId=${
        fieldsOfApplicationId || ""
      }&legalAidId=${legalAidId || ""}&search=${search || ""}`,
      params
    );
  },
  ownAccepted({ search, params, fieldsOfApplicationId, legalAidId }) {
    return ApiService.post(
      `${main}/ownAccepted?fieldsOfApplicationId=${
        fieldsOfApplicationId || ""
      }&legalAidId=${legalAidId || ""}&search=${search || ""}`,
      params
    );
  },
  create(data) {
    let form = new FormData();
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        form.append(key, data[key]);
      }
    }
    return ApiService.post(`${main}/create`, form);
  },
  // http://localhost:8282/api/v.1/legalAidDisabled/accept?legalAidDisabledId=16541
  accept(legalAidDisabledId) {
    return ApiService.get(
      `${main}/accept?legalAidDisabledId=${legalAidDisabledId}`
    );
  },
  fieldsOfApplicationList({ search, params }) {
    return ApiService.post(
      `${main}/fieldsOfApplicationList?search=${search || ""}`,
      params
    );
  },
  directoryLegalAidList({ search, params }) {
    return ApiService.post(
      `getInfo/directoryLegalAidList?search=${search || ""}`,
      params
    );
  },
  checkDisability({ pinfl, passportSerialNumber }) {
    return ApiService.get(
      `${main}/checkDisability?pinfl=${pinfl || ""}&passportSerialNumber=${
        passportSerialNumber || ""
      }`
    );
  },
  getById(id) {
    return ApiService.get(`${main}/get-by-id?id=${id}`);
  },
  complete({ demand, legalAidDisabledId }) {
    return ApiService.get(
      `${main}/complete?demand=${demand}&legalAidDisabledId=${legalAidDisabledId}`
    );
  },
  // http://localhost:8282/api/v.1/getInfo/getLawyerInfoDto/{{id}}
  getLawyerInfoDto(id) {
    return ApiService.get(`getInfo/getLawyerInfoDto/${id}`);
  },
};
